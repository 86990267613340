@import 'src/style/sizes';

.title-huge,
.title-large,
.title-regular,
.title-very-large {
  margin: 0;
  font-weight: 400;
  font-family: EinaB;
  line-height: 1.3;
}

.title-huge {
  font-size: $titleHuge;
}

.title-very-large {
  font-size: $titleVeryLarge;
}

.title-large {
  font-size: $titleLarge;
}

.title-regular {
  font-size: $titleRegular;
}
