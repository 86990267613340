@import 'src/style/sizes';
@import 'src/style/colours';

.date-box {
  height: 80px;
  position: relative;
  width: 80px;
  border-radius: $radiusRegular;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title-regular {
    padding-bottom: 4px;
  }

  .body-small {
    line-height: 1;
  }
}

.date-box-icon {
  position: absolute;
  top: $paddingVerySmall;
  left: $paddingVerySmall;
}

.date-box-pre {
  color: $accentTertiary;
  background: $backgroundTertiary;
}

.date-box-post {
  color: $positive;
  background: $backgroundPositive;
}
