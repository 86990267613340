@import 'src/style/sizes';
@import 'src/style/colours';

.input-cont {
  display: block;
  margin-bottom: $paddingLarge;
  position: relative;
}

.input-label {
  display: block;
}

.input {
  height: $button;
  padding: 0 $paddingRegular;
  border: 1px solid $divider;
  font: $titleRegular EinaB;
  border-radius: $radiusRegular;
  appearance: none;
  transition: border 0.3s ease;
  width: 100%;
  text-transform: none !important;
}

.input::placeholder {
  color: $textSecondary;
}

.input:hover,
.input:focus {
  transition: border 0s ease;
  outline: 0;
  border: 1px solid $accentTertiary;
}

.input-haserror {
  border: 1px solid $destructive !important;
}

.input.input-hasaction {
  border-radius: $radiusRegular 0 0 $radiusRegular;
}

.input-cont .input-action {
  border-radius: 0 $radiusRegular $radiusRegular 0;
}

.input-error {
  margin-top: $paddingSmall;
  color: $destructive;
}

.input-cont .input-cont {
  margin-bottom: 0;
}

.input-cont-invert {
  color: $white;

  .input {
    caret-color: $white;
    border: 1px solid $white;
    background: $accentTertiary;
    color: $white;
  }

  .input::placeholder {
    color: $white;
    opacity: .3;
  }
}

.input-success {
  position: absolute;
  top: ($button - $iconRegular) / 2;
  right: ($button - $iconRegular) / 2;
}
