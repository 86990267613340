@mixin small-up {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin medium-up {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin large-up {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin huge-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin small-down {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin medium-down {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin large-down {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin huge-down {
  @media (max-width: 1200px) {
    @content;
  }
}
