@import 'src/style/colours';
@import 'src/style/sizes';

.page-header {
  background-color: white;
  height: 68px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 10;
}

.header-items-container {
  display: flex;
  align-items: center;
}

.header-logo {
  margin: $paddingRegular 0;
  margin-right: auto;
}

.nav-link {
  margin-right: 32px;
  justify-content: flex-end;
}

.header-sign-up-btn {
  width: 100px;
}

.header-user-image {
  height: 36px;
  width: 36px;
  border-radius: 18px;
}
