$paddingVerySmall: 4px;
$paddingSmall: 8px;
$paddingRegular: 16px;
$paddingLarge: 24px;
$paddingVeryLarge: 32px;
$paddingHuge: 40px;
$paddingMassive: 56px;
$paddingSuperMassive: 80px;
$paddingMega: 120px;
$paddingColossal: 136px;
$paddingMegaColossal: 220px;

$iconSmall: 16px;
$iconRegular: 24px;
$iconLarge: 40px;
$border: 1px;

$radiusRegular: 8px;

$button: 50px;
$buttonWidth: 200px;
$buttonSmall: 14px;
$buttonRegular: 16px;

$titleRegular: 16px;
$titleLarge: 26px;
$titleVeryLarge: 34px;
$titleHuge: 56px;

$bodySmall: 14px;
$bodyRegular: 16px;

:export {
  paddingVerySmall: $paddingVerySmall;
  paddingSmall: $paddingSmall;
  paddingRegular: $paddingRegular;
  paddingLarge: $paddingLarge;
  paddingVeryLarge: $paddingVeryLarge;
  paddingHuge: $paddingHuge;
  paddingMassive: $paddingMassive;
  paddingSuperMassive: $paddingSuperMassive;
  paddingMega: $paddingMega;
  paddingColossal: $paddingColossal;
  paddingMegaColossal: $paddingMegaColossal;

  iconSmall: $iconSmall;
  iconRegular: $iconRegular;
  iconLarge: $iconLarge;

  border: $border;

  radiusRegular: $radiusRegular;

  button: $button;
  buttonWidth: $buttonWidth;
  buttonSmall: $buttonSmall;
  buttonRegular: $buttonRegular;

  titleRegular: $titleRegular;
  titleLarge: $titleLarge;
  titleVeryLarge: $titleVeryLarge;
  titleHuge: $titleHuge;

  bodySmall: $bodySmall;
  bodyRegular: $bodyRegular;
}
