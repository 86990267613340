@import 'src/style/colours';

.image-cont-aspect {
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.image-cont-loading {
  background: $backgroundLight;
}

.image-cont-img {
  display: inline-block;
  font-size: 0;
  line-height: 0;

  &.image-cont-failed {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      height: auto;
      width: auto;
    }
  }
}

.image-cont-failed {
  position: relative;

  .image-error {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
