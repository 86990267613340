@import 'src/style/colours';
@import 'src/style/sizes';
@import 'src/style/medias';

.footer {
  padding: $paddingSuperMassive 0 $paddingMega;
  background: $backgroundLight;
}

.footer-cont,
.footer-link-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-logo-cont {
  display: flex;
  flex-direction: column;
  margin-bottom: $paddingMassive;
}

.footer-link-group {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: $paddingHuge;
  }
}

.footer-group-link {
  text-align: center;
  margin-bottom: $paddingSmall;
}

@include large-up {
  .footer {
    padding: $paddingSuperMassive 0 ($paddingSuperMassive * 2);
  }

  .footer-cont,
  .footer-link-cont {
    flex-direction: row;
    align-items: initial;
  }

  .footer-logo-cont {
    margin-right: auto;
  }

  .footer-link-group {
    + .footer-link-group {
        margin-left: $paddingSuperMassive;
    }
  }

  .footer-group-link {
    text-align: left;
  }
}

.footer-legal {
  margin-top: $paddingRegular;
  color: $textSecondary;
  font: $bodySmall EinaB;
}

.footer-group-title {
  margin-bottom: $paddingLarge;
}
