@import 'src/style/sizes';

.body-regular {
  font: 400 #{$bodyRegular}/1.75 EinaR;
  margin: 0;
}

.body-small {
  font: 400 #{$bodySmall}/1.75 EinaB;
  margin: 0;
}
