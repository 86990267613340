@import 'src/style/sizes.scss';

//.header-huge.header-subtitle {
  //padding-bottom: $paddingRegular;
//}

//.header-regular.header-subtitle,
//.header-large.header-subtitle,
//.header-very-large.header-subtitle {
  //padding-bottom: $paddingSmall;
//}

//.header-regular .header-subtext,
//.header-large .header-subtext {
  //padding-bottom: $paddingHuge;
//}

//.header-very-large {
  //padding-top: $paddingSuperMassive;
//}
//
.header-regular {
    &.header-subtitle {
        padding-bottom: $paddingVerySmall;
    }
}

.header-large {
    &.header-subtitle {
        padding-bottom: $paddingSmall;

        + .header-subtext {
            padding-bottom: $paddingHuge;
        }
    }
}

.header-very-large {
    &.header-subtitle {
        padding-top: $paddingSuperMassive;
        padding-bottom: $paddingSmall;

        + .header-subtitle {
            padding-bottom: $paddingHuge;
        }
    }
}

.header-huge {
    &.header-subtitle {
        padding-bottom: $paddingRegular;
    }
}
