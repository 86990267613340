@import 'src/style/colours';
@import 'src/style/sizes';

.btn {
  appearance: none;
  border: 0;
  border-width: $border;
  height: $button;
  width: $buttonWidth;
  border-radius: $radiusRegular;
  font-family: EinaB;
  font-weight: 400;
  font-size: $buttonRegular;
  cursor: pointer;
}

.btn-primary {
  background-color: $accentPrimary;
  border-color: $accentPrimary;
  color: white;
}

.btn-accent-primary {
  border-color: $accentPrimary;
  opacity: 20%;
  color: $accentPrimary;
}

.btn-destructive-primary {
  background-color: $destructive;
  border-color: $destructive;
  color: white;
}

.btn-destructive-secondary {
  border-color: $destructive;
  color: $destructive;
}

.btn-block {
  display: block;
  width: 100%;
}
