@import 'src/style/sizes';

.header-very-large {
  padding: $paddingHuge 0 $paddingSuperMassive;
}

.header-large {
  padding-bottom: $paddingHuge;
}

.header-regular {
  padding-bottom: $paddingRegular;
}
