$accentPrimary: #ff5c3a;
$accentSecondary: #502c8d;
$accentTertiary: #045cdf;
$divider: #ffcfd5;
$backgroundLight: #fff7f8;
$backgroundDark: #0a0019;
$backgroundPositive: #E7FBF1;
$backgroundTertiary: #E5EEFB;
$destructive: #dd162e;
$positive: #16dd76;
$textPrimary: #0a0019;
$textSecondary: #cfccd5;
$white: #fff;

:export {
  accentPrimary: $accentPrimary;
  accentSecondary: $accentSecondary;
  accentTertiary: $accentTertiary;
  divider: $divider;
  backgroundLight: $backgroundLight;
  backgroundDark: $backgroundDark;
  backgroundPositive: $backgroundPositive;
  backgroundTertiary: $backgroundTertiary;
  destructive: $destructive;
  positive: $positive;
  textPrimary: $textPrimary;
  textSecondary: $textSecondary;
  white: $white;
}
