@import 'src/style/sizes';
@import 'src/style/colours';
@import 'src/style/medias';

.hero-header {
  padding-bottom: $paddingLarge;

  .header-subtitle {
    padding-bottom: $paddingSmall;
  }

  .header-subtext {
    padding-bottom: $paddingVeryLarge;
  }
}

.features {
  padding-top: $paddingMassive;
  padding-bottom: $paddingMassive;
}

.explan-bg {
  padding-top: $paddingHuge;
}

.itinerary-header,
.bookings-header {
  .header-subtitle {
    padding-bottom: $paddingSmall;
  }

  .header-subtext {
    padding-bottom: $paddingVeryLarge;
  }
}

.payments {
  padding-bottom: $paddingSuperMassive;
}

.payments-example,
.bookings-example {
  img {
    max-width: 100%;
  }
}

.payments-example {
  padding-bottom: $paddingMega;
  display: block;
}

.payment-cards {
  border-top: 2px solid $divider;
  padding-top: $paddingVeryLarge;
}

.payment-header {
  padding-bottom: $paddingSmall;
}

.payment-header-subtext {
  padding-bottom: 0;
}

.payment-cards-subtext {
  padding-bottom: $paddingLarge;
}

.payment-accept {
  margin-top: $paddingLarge;
}

.commission {
  padding-top: $paddingMassive;

  .header-subtitle {
    padding-bottom: $paddingSmall;
  }
}

.comm-body {
    padding-bottom: $paddingSuperMassive;
}

.join-us {
  position: relative;
  padding: $paddingVeryLarge;
  background: $accentTertiary;
  margin-bottom: $paddingMega;
}

.join-us-header {
  color: $white;

  .join-us-title {
    padding: 0;
  }

  .header-subtext {
    padding-bottom: $paddingVeryLarge;
  }
}

.join-us-logo {
  position: absolute;
  top: 0;
  left: 0;
}

.tribe {
  padding: 0 0 $paddingVeryLarge;
}

@include medium-up {
  .hero {
    padding-top: 135px;
  }

  .hero-header {
    width: 70%;
    padding-bottom: 190px;

    .header-subtitle {
      padding-bottom: $paddingRegular;
    }
  }

  .explan-bg {
    padding-top: $paddingMegaColossal;
  }

  .itinerary-header,
  .bookings-header {
    width: 70%;

    .header-subtitle {
      padding-bottom: $paddingRegular;
    }

    .header-subtext {
      padding-bottom: $paddingMassive;
    }
  }

  .payments-example {
    padding-bottom: $paddingMegaColossal;
  }

  .bookings-example {
    margin-bottom: $paddingColossal;
  }

  .payments {
    padding-bottom: $paddingColossal;
  }

  .payment-cards {
    border-top: 0;
    border-left: 2px solid $divider;
    padding-top: 0;
    padding-left: $paddingMassive;
  }

  .payment-header {
    padding-bottom: $paddingRegular;
  }

  .payment-header-subtext {
    padding-top: 0;
  }

  .commission {
    padding-top: $paddingMega;

    .header-subtitle {
      padding-bottom: $paddingRegular;
    }

    .header-subtext {
      padding-bottom: $paddingRegular;
      width: 70%;
    }
  }

  .comm-body {
      width: 70%;
      padding-bottom: $paddingColossal;
  }

  .join-us {
    padding: $paddingMassive;
    border-radius: $radiusRegular;
  }

  .join-us-header {
    .header-subtext {
      padding: 0;
    }
  }

  .tribe {
    padding: 0 $paddingHuge $paddingVeryLarge;
  }
}

@include large-up {
  .hero {
    background-position: top right;
    background-image: url(../assets/desktop/hero.jpg);
    background-size: contain;
  }

  .hero-header {
    width: 40%;
    padding-bottom: 190px;

    .header-subtitle {
      padding-bottom: $paddingRegular;
    }
  }
}
